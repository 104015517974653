<template>
  <v-container fluid class="pa-0">
    <v-score />
    <v-row no-gutters>
      <v-col
        cols="12"
        :key="index"
        v-for="(item, index) in items"
        @click="lerItem(item)"
      >
        <v-card color="primary" class="elevation-0">
          <v-card-text
            :class="
              (item.lido ? '' : 'text-truncate') + ' px-3 py-5 white--text'
            "
          >
            {{ item.descricao }}
            <v-icon
              color="primary"
              v-if="item.lido && item.link"
              size="12"
              right
              >fas fa-external-link-alt</v-icon
            >
            <v-chip
              :disabled="item.lido"
              class="mt-1"
              small
              :style="{ float: 'right' }"
            >
              {{ item.data }}
              &nbsp;
              <v-icon
                      class="ml-2"
                style="fontSize: 10px; verticalAlign: baseline;"
                >{{
                  item.lido ? "fas fa-check" : "fas fa-circle red--text"
                }}</v-icon
              >
            </v-chip>
            <div class="clearBoth" />
          </v-card-text>
        </v-card>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import score from "../../components/protecoes/score";

export default {
  components: {
    "v-score": score,
  },
  methods: {
    ...mapMutations(["showmsg", "updateBadges"]),
    lerItem(item) {
      item.lido = true
    }
  },
  computed: {
    ...mapState(["badges"])
  },
  data: () => ({
    pontos: '671',
    items: [{
      descricao: '+271 pontos adicionados',
      data: '2020-12-04',
      lido: false
    },{
      descricao: '+200 pontos adicionados',
      data: '2020-10-12',
      lido: false
    },
    {
      descricao: '+100 pontos adicionados',
      data: '2020-10-10',
      lido: false
    }]
  })
};
</script>
<style scoped>
.score-pts {
  text-align: center;
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 10px
}

</style>
