<template>
  <v-row>
    <v-col>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        "
        class="text-center"
        @click="$util.link('pontos');"
      >
        <p class="pontuacao-number white--text" id="v-score-8">671</p>
        <div>
          <span class="pontuacao-pts white--text"> pts </span>
          <span class="pontuacao-score white--text">(score)</span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    registros: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
};
</script>
<style scoped>
.pontuacao-number {
  font-size: 80px;
}
.pontuacao-pts {
  font-size: 25px;
}

.pontuacao-score {
  display: block;
  font-size: 12px;
}
</style>